import React from 'react'
import PropTypes from 'prop-types'
import { LanguageProvider } from 'src/context/LanguageContext'

/* eslint-disable import/prefer-default-export */
export const wrapRootElement = ({ element }) => (
  <LanguageProvider>{element}</LanguageProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
