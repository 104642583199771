import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  typography: {
    fontFamily: " 'Titillium Web', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif ",
    fontSize: 15,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "white",
        },
        a: {
          color: "#1FDC6B",
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      containedSizeLarge: {
        height: '55px',
        paddingLeft: '40px',
        paddingRight: '40px',
      },
      root: {
        fontFamily: " 'Titillium Web', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif ",
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '100px',
      },
    },
  },
  palette: {
    primary: {
      main: '#1FDC6B',
    },
    secondary: {
      main: '#76BBF8',
    },

    patients: {
      main: '#7B90C2',
    },
    lightgrey: {
      main: '#E6E6E6',
    },
    error: {
      main: '#F44336',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1776,
    },
  },
})

export default theme
