exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-wrappers-career-js": () => import("./../../../src/templates/wrappers/career.js" /* webpackChunkName: "component---src-templates-wrappers-career-js" */),
  "component---src-templates-wrappers-company-information-js": () => import("./../../../src/templates/wrappers/companyInformation.js" /* webpackChunkName: "component---src-templates-wrappers-company-information-js" */),
  "component---src-templates-wrappers-contact-js": () => import("./../../../src/templates/wrappers/contact.js" /* webpackChunkName: "component---src-templates-wrappers-contact-js" */),
  "component---src-templates-wrappers-default-js": () => import("./../../../src/templates/wrappers/default.js" /* webpackChunkName: "component---src-templates-wrappers-default-js" */),
  "component---src-templates-wrappers-development-field-js": () => import("./../../../src/templates/wrappers/developmentField.js" /* webpackChunkName: "component---src-templates-wrappers-development-field-js" */),
  "component---src-templates-wrappers-development-fields-js": () => import("./../../../src/templates/wrappers/developmentFields.js" /* webpackChunkName: "component---src-templates-wrappers-development-fields-js" */),
  "component---src-templates-wrappers-home-js": () => import("./../../../src/templates/wrappers/home.js" /* webpackChunkName: "component---src-templates-wrappers-home-js" */),
  "component---src-templates-wrappers-mission-vision-js": () => import("./../../../src/templates/wrappers/missionVision.js" /* webpackChunkName: "component---src-templates-wrappers-mission-vision-js" */),
  "component---src-templates-wrappers-patients-js": () => import("./../../../src/templates/wrappers/patients.js" /* webpackChunkName: "component---src-templates-wrappers-patients-js" */),
  "component---src-templates-wrappers-services-js": () => import("./../../../src/templates/wrappers/services.js" /* webpackChunkName: "component---src-templates-wrappers-services-js" */)
}

