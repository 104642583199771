export const getTranslatedMenu = (
  edges,
  currentLocale,
  defaultLanguage,
  location
) => {
  let menuLocation = location
  if (currentLocale !== defaultLanguage) {
    menuLocation = `${location}___${currentLocale
      .substring(0, 2)
      .toUpperCase()}`
  }

  return edges.filter(({ node }) => {
    return node.locations.includes(menuLocation)
  })
}

export const getTranslatedHomeUri = language => {
  return language.defaultTranslations.filter(obj => {
    return obj.locale === language.currentLocale
  })[0].uri
}

export const getRelativeUrl = url => {
  return url.replace(/.*\/\/[^/]*/, '')
}
