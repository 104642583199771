import React, { useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { getRelativeUrl } from 'src/utils/urlUtils'

const defaultValues = {
  currentLocale: 'de_AT',
  currentTranslations: [],
  defaultTranslations: [
    {
      locale: 'de_AT',
      uri: '/',
    },
  ],
}

const LanguageContext = React.createContext(defaultValues)
LanguageContext.displayName = 'Language Context'

const LanguageProvider = ({ children }) => {
  // According to the Polylang documentation the front-page must be translated for all set languages
  // We use this fact to garuante that all available languages are queried
  const data = useStaticQuery(graphql`
    query LanguageContextQuery {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        edges {
          node {
            link
            language {
              locale
            }
            translations {
              link
              language {
                locale
              }
            }
          }
        }
      }
    }
  `)

  const getTranslationsObjectFromNode = node => {
    const current = {
      locale: node.language.locale,
      uri: node.link && getRelativeUrl(node.link),
    }

    const currentTranslations = node.translations.map(translation => {
      return {
        locale: translation.language.locale,
        uri: translation.link && getRelativeUrl(translation.link),
      }
    })

    return [current, ...currentTranslations]
  }

  const translationsDefault = getTranslationsObjectFromNode(
    data.allWpPage.edges[0].node
  )
  const [currentTranslations, setCurrentTranslations] = useState(
    translationsDefault
  )
  const [defaultTranslations] = useState(translationsDefault)

  const containedInNestedObject = (needle, haystack) => {
    if (JSON.stringify(haystack).indexOf(needle) > -1) {
      return true
    }
    return false
  }

  const validateLocale = locale => {
    const containedInDefaults = containedInNestedObject(
      locale,
      translationsDefault
    )
    return containedInDefaults
      ? locale
      : defaultValues.defaultTranslations[0].locale
  }

  const [currentLocale, setCurrentLocale] = useState(
    validateLocale(localStorage.getItem('currentLocale'))
  )

  const updateLocale = locale => {
    setCurrentLocale(validateLocale(locale))
  }

  const updateTranslations = node => {
    setCurrentTranslations(getTranslationsObjectFromNode(node))
  }

  const getDefaultTranslationByLocale = locale => {
    return defaultTranslations.filter(node => {
      return node.locale === locale
    })[0]
  }

  const updateLocaleAndRedirect = locale => {
    const current = currentTranslations.filter(node => {
      return node.locale === locale
    })[0]

    const redirectUri =
      current && current.uri
        ? current.uri
        : getDefaultTranslationByLocale(locale).uri
    navigate(redirectUri)
  }

  return (
    <LanguageContext.Provider
      value={{
        currentLocale,
        currentTranslations,
        defaultTranslations,
        updateLocale,
        updateTranslations,
        updateLocaleAndRedirect,
      }}
    >

      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LanguageContext
export { LanguageProvider }
